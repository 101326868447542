


/*

 Loaders (for buttons)

 */

function loader_init() {
    $btn = $(".loader-on-page-load");
    loader_add($btn);
    $btn.prop("disabled", true);
}

function loader_add($o) {
    var loader = '<span class="loader"><svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\
    width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">\
        <path fill="#fff" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">\
        <animateTransform attributeType="xml"\
            attributeName="transform"\
            type="rotate"\
            from="0 25 25"\
            to="360 25 25"\
            dur="0.6s"\
            repeatCount="indefinite"/>\
        </path>\
        </svg></div>';
    if ($o.closest('.btn').length) {
        $o.wrapInner("<div class='btn__loader-inner'>");
    }
    $o.append(loader).addClass("loading");
    var $loader = $o.find("> .loader");
    $loader.addClass("active");
}

function loader_remove($o) {
    $o.find(".btn__loader-inner").children().unwrap();
    $o.removeClass("loading");
    var $loader = $o.find("> .loader");
    $loader.removeClass("active");
    $loader.remove();
}




/*

Gallery and Modal Popups

 */

function fancybox_init() {

    if (!$("html").hasClass("fancybox-inited")) {

        $("html").addClass("fancybox-inited");

        $.fancybox.defaults.i18n.ru = {
            CLOSE: "Закрыть",
            NEXT: "Далее",
            PREV: "Назад",
            ERROR: "Загрузка контента не удалась. <br/> Пожалуйста, попробуйте ещё раз.",
            PLAY_START: "Начать слайдшоу",
            PLAY_STOP: "Приостановить слайдшоу",
            FULL_SCREEN: "Во весь экран",
            THUMBS: "Предпросмотр",
            DOWNLOAD: "Скачать",
            SHARE: "Поделиться",
            ZOOM: "Масштаб"
        };
        $.fancybox.defaults.lang = $('html').attr('lang');

        if ($().fancybox) {
            $.fancybox.options_default = {
                slideShow: false,
                hash: false,
                loop: true,
                idleTime: 10,
                margin: [44, 0],
                gutter: 50,
                keyboard: true,
                animationEffect: "zoom",
                arrows: true,
                infobar: true,
                toolbar: true,
                buttons: [
                    'slideShow',
                    'fullScreen',
                    'thumbs',
                    'close'
                ],
                smallBtn: true,
                btnTpl : {
                    smallBtn: '<div data-fancybox-close class="fancybox-close"><svg class="icon icon--cross"><use xlink:href="#icon-cross"></use></svg></div>'
                },
                thumbs: {
                    autoStart: false,
                    hideOnClose: true
                }
            };

            $.fancybox.options_modal = {
                animationDuration: 700,
                parentEl: ".wrap",
                slideShow: false,
                hash: false,
                keyboard: false,
                ajax: {
                    settings: {
                        cache: false
                    }
                },
                hideScrollbar: true,
                baseClass: "fancybox-container--popup",
                trapFocus: false,
                autoFocus: false,
                touch: false,
                popup_default: true,
                btnTpl : {
                    smallBtn: '<div data-fancybox-close class="fancybox-close"><svg class="icon icon--cross"><use xlink:href="#icon-cross"></use></svg></div>'
                },
                afterLoad: function (instance, current) {
                    current.$content.wrap("<div>");
                    if (current.$content.hasClass('fancybox-content')) {
                        current.$content.removeClass('fancybox-content').parent().addClass('fancybox-content');
                    }
                    bind_widgets(current.$content);
                },
                afterShow: function (instance, current) {
                    if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                        Blazy.revalidate();
                    }
                },
                beforeClose: function (instance, current) {
                    if (current.$content) {
                        current.$content.closest(".fancybox-container").addClass('fancybox-is-close');
                    }
                },
            };

            $.fancybox.defaults.afterLoad = function (instance, current) {
                current.$content.closest('.fancybox-container').addClass('fancybox-container--'+current.type);
            };
            $.fancybox.defaults.beforeLoad = function (instance, current) {
                if ($().tooltipster) {
                    var tooltips = $.tooltipster.instances();
                    $.each(tooltips, function (i, tooltip) {
                        tooltip.close();
                    });
                }
            };
            $.fancybox.defaults.hash = false;
            $.fancybox.defaults.errorTpl = '<div><div class="panel panel--compact"><div class="panel__content text-align-center"><p>Произошла ошибка при загрузке. <br /> Попробуйте ещё раз.</p></div></div></div>';
        }

        $('body').on('mousewheel', function (e) {
            if ($(".fancybox-is-zoomable").length) {
                e.preventDefault();
                var instance = $.fancybox.getInstance();
                if ($(".fancybox-is-zoomable").length && instance.canPan() && e.deltaY > 0) {
                    instance.scaleToFit();
                } else if ($(".fancybox-can-zoomIn").length && instance.isScaledDown() && e.deltaY < 0) {
                    instance.scaleToActual(e.clientX, e.clientY);
                }
            }
        });
    }

    if ($().fancybox) {

        var options = $.fancybox.options_default;
        $fancybox_links_all = $("[data-fancybox]").not(".fancybox-inited");

        $fancybox_links = $fancybox_links_all.not("[data-type='ajax'], [data-type='inline']");
        fancybox_links_by_group = [];
        groups = [];
        $fancybox_links.each(function () {
            var group = $(this).attr("data-fancybox");
            if (!group) group = "";
            if ($.inArray(group, groups) < 0) groups.push(group);
        });
        for (group in groups) {
            options_current = $.extend(true, {}, options);
            var $items = $fancybox_links.filter("[data-fancybox='" + groups[group] + "']");
            var $first = $items.eq(0);
            if (typeof $first.attr("data-fancybox-loop") !== "undefined") {
                options_current["loop"] = $first.data("fancybox-loop");
            }
            $items.fancybox(options_current).addClass("fancybox-inited");
        }

        $fancybox_links_ajax = $fancybox_links_all.filter("[data-type='ajax'], [data-type='inline']");
        $fancybox_links_ajax.each(function () {
            var options = $.fancybox.options_modal;
            if ($(this).data('ajax-type')) {
                options = $.extend({}, options, {
                    ajax : {
                        settings: {
                            type: $(this).data('ajax-type'),
                        }
                    }
                });
            }
            if ($(this).data('ajax-data')) {
                options = $.extend({}, options, {
                    ajax : {
                        settings: {
                            data: $(this).data('ajax-data'),
                        }
                    }
                });
            }
            if ($(this).data('popup-class')) {
                options = $.extend({}, options, {
                    baseClass : options.baseClass + ' ' + $(this).data('popup-class')
                });
            }
            $(this).fancybox(options);
        }).addClass("fancybox-inited");
    }
}

