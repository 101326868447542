
/*

 Click and touch events helpers

 */

function click_touch_init() {

    $(document).on("click.touchfocused", ".touchevents .touch-focused, .touch-focused-all", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused")) {
            if (!$o.hasClass("disable-prevent-link-clicks")) {
                e.preventDefault();
            }
            $(".touch-focused").not($o).not($o.closest(".touch-focused")).removeClass("focused");
            $o.addClass("focused");
        }
        else {
            if ($o.hasClass('touch-focused-toggle')) {
                $o.removeClass("focused");
            }
        }
    });

    $(document).on("click", ".touchevents .touch-focused a, .touch-focused-all a", function (e) {
        var $tf = $(this).closest(".touch-focused");
        if (!$tf.hasClass("focused") && !$tf.hasClass("disable-prevent-link-clicks")) {
            e.preventDefault();
        }
    });

    $(document).on("click touchstart", "*", function (e) {
        if (!$(e.target).closest(".touch-focused").length) {
            $(".touch-focused").removeClass("focused");
        }
    });

    $(document).on("click", ".no-click", function (e) {
        e.preventDefault();
    });

    $(document).on("click", ".no-propagation", function (e) {
        e.stopPropagation();
    });

}



/*

Responsive design helpers

 */

function responsive_init() {
    responsive_update();
}

function responsive_update(force, no_animation) {
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body").addClass('no-transition no-animation');
    }
    if (typeof force === "undefined") force = false;
    var ww = window.innerWidth;
    if ($("body").data("ww") != ww || force)
    {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
        $("[data-place]").each(function(){
            var places = $(this).data("place");
            var breakpoints = Object.keys(places).map(function(value) {
                return parseInt(value);
            }).sort(function(a,b) {
                return a - b;
            }).reverse();
            for (i in breakpoints) {
                if (window.matchMedia("(min-width: "+breakpoints[i]+"px)").matches) {
                    if ($(places[breakpoints[i]]).length)  {
                        if (!$(this).next(places[breakpoints[i]]).length) {
                            $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                        }
                    }
                    break;
                }
            }
        });

        $("body").data("ww", ww);
    }
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body")[0].offsetHeight;
        $("body").removeClass('no-transition no-animation');
    }
}




/*

Scroll Lazy Loading

 */

function blazy_init(container) {

    $('.js-blazy-revalidate-on-scroll').on('scroll', function(){
        blazy_revalidate_on_scroll_delay(function(){
            if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                Blazy.revalidate();
            }
        }, 50);
    });

    window.Blazy = new Blazy({
        container: (container)?container:null,
        offset: 0,
        success: function(ele){
            var $o = $(ele).closest(".img-to-bg");
            img_to_bg($o);
            $o.addClass('img-to-bg-lazy-loaded');
            blazy_callback(ele);
        }
        , error: function(ele, msg){
            blazy_callback(ele);
            if(msg === 'missing'){
            }
            else if(msg === 'invalid'){
            }
        }
    });

    $(window).on('resize', function(){
        blazy_revalidate_delay(function(){
            blazy_revalidate();
        }, 100);
    });

    $(document).on('shown.bs.modal', function () {
        blazy_revalidate();
    });

    $('.js-slick-slider-revalidate-image').on('init reInit setPosition', function(event){
        blazy_revalidate();
    });
}

function blazy_callback(ele) {
    var callback = $(ele).not('.blazy-callback-executed').data('src-callback');
    if (callback) {
        var callback_params = $(ele).data('src-callback-params');
        if (!callback_params) {
            callback_params = [];
        }
        var fn = getFunctionFromString(callback);
        if (typeof fn === "function") {
            $(ele).addClass('blazy-callback-executed');
            fn.apply(null, callback_params);
        }
    }
}

function blazy_revalidate() {
    if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
        Blazy.revalidate();
    }
}

var blazy_revalidate_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

var blazy_revalidate_on_scroll_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();




/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o, context) {

    if (!$("body").hasClass("img-to-bg-inited")) {

        $(window).on("resize", function(){
            delay_img_to_bg(function(){
                img_to_bg($(".img-to-bg-resp"));
            }, 100);
        });

        $("body").addClass("img-to-bg-inited");
    }

    if (typeof $o === "undefined" || !$o) $o = $(".img-to-bg", context);
    $o.each(function () {
        var $imgtobg = $(this);
        var $img = $imgtobg.find("> img").first();
        if (!$img.length) {
            $img = $imgtobg.find("> picture img").first();
        }
        var src = "";
        if ($img.length) {
            var src = $img[0].currentSrc;
        }
        if (!src) {
            src = $img.attr('src');
        }
        if ($img.length && src) {
            $imgtobg.css("background-image", "url('" + src + "')");
            $imgtobg.addClass("img-to-bg--inited");
        }
    });
}

var delay_img_to_bg = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();


/*

Fix buggy 100vh behaviour on mobiles

 */

function fix_100vh_init() {
    if (!Modernizr.touchevents) return;

    fix_100vh();
    $("body").data("fix_100vh_ww", $(window).width());

    $(window).on("resize", function (e) {
        if ($("body").data("fix_100vh_ww") != $(window).width()) {
            fix_100vh();
        }
        $("body").data("fix_100vh_ww", $(window).width());
    });
}

function fix_100vh() {
    $(".js-fix-100vh").each(function(){
        $(this).css("height", window.innerHeight);
    });
    $(".js-fix-100vh-minus-header").each(function(){
        $(this).css("height", window.innerHeight - $('.header + .header-replace')[0].offsetHeight);
    });
    $(".js-fix-100vh-min").each(function(){
        $(this).css("min-height", window.innerHeight);
    });
    $(".js-fix-100vh-max").each(function(){
        $(this).css("max-height", window.innerHeight);
    });
}






/*

Hide/Show blocks

 */

function toggle_element_init()
{
    $(document).on("click click-pseudo change", ".toggle-element, .show-element, .hide-element", function (e) {
        if ($(this).is("a")) e.preventDefault();
        if (e.type != "change" && $(this).is(":checkbox,:radio")) {
            return true;
        }
        var callback = $(this).data('toggle-condition');
        var condition = true;
        var condition_child = $(this).data('toggle-condition-child');
        if (typeof condition_child !== 'undefined') {
            condition = condition_child;
        }
        else {
            if (callback) {
                var callback_params = $(this).data('toggle-condition-args');
                if (!callback_params) {
                    callback_params = [];
                }
                var fn = getFunctionFromString(callback);
                if (typeof fn === "function") {
                    condition = fn.apply(null, callback_params);
                }
                var $parents = $(this).parents('[data-toggle-condition]');
                $parents.data('toggle-condition-child', condition);
            }
        }
        if (!condition) {
            return true;
        }
        var $o = $();
        if ($(this).attr("href") && $(this).attr("href").length > 1) {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) {
            $o = $($(this).data("selector"));
        }
        var restore_scroll = $(this).data("toggle-restore-scroll-position");
        if (restore_scroll) {
            var scroll_pos = $(window).scrollTop();
            var $scroll_relative = $(this);
            var scroll_relative = $(this).data('toggle-restore-scroll-relative');
            if (scroll_relative) {
                $scroll_relative = $(scroll_relative);
            }
            var offset_pos = parseInt($scroll_relative.offset().top, 10);
        }
        var class_name = "hidden";
        if ($(this).data("toggle-class")) {
            class_name = $(this).data("toggle-class");
        }
        var element_class_name = "active";
        if ($(this).data("toggle-element-class")) {
            element_class_name = $(this).data("toggle-element-class");
        }
        var change_label = false;
        if ($(this).hasClass("toggle-element")) {
            change_label = true;
            if ($(this).is(":checkbox,:radio")) {
                if (e.type == "change") {
                    $o.toggleClass(class_name, !$(this).prop("checked"));
                }
            }
            else {
                $(this).toggleClass(element_class_name);
                $o.toggleClass(class_name);
            }
            $o.trigger("toggle-element");
        }
        if ($(this).hasClass("show-element")) {
            $o_s = $($(this).data("selector-show"));
            if ($o_s.length) {
                $o = $o_s;
            }
            if (!$(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !!$(this).data("toggle-reverse"));
            $o.trigger("show-element");
        }
        if ($(this).hasClass("hide-element")) {
            $o_h = $($(this).data("selector-hide"));
            if ($o_h.length) {
                $o = $o_h;
            }
            if ($(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !$(this).data("toggle-reverse"));
            $o.trigger("hide-element");
        }
        if (change_label && $(this).data("toggle-change-label")) {
            var label = $(this).html();
            $(this).html($(this).attr("data-toggle-change-label"));
            $(this).attr("data-toggle-change-label", label);
        }
        var $siblings = $(this).siblings();
        if ($(this).data("toggle-siblings-selector")) {
            $siblings = $(this).closest($(this).data("toggle-siblings-selector")).siblings();
        }
        if ($(this).data("toggle-current-selector")) {
            $siblings = $siblings.find($(this).data("toggle-current-selector"));
        }
        $siblings.removeClass(element_class_name);
        if (restore_scroll) {
            var scroll_pos_new = $(window).scrollTop();
            var offset_pos_new = parseInt($scroll_relative.offset().top, 10);
            $(window).scrollTop(offset_pos_new - (offset_pos - scroll_pos));
        }
    });
}


/*

Scroll to needed objects

 */

function goto_init() {
    $(document).on("click", ".js-goto", function (e) {
        var href = $(this).attr("href");
        var hash = href.split('#');
        if (hash.length > 1) {
            hash = '#'+hash.pop();
            var $o = $(hash);
            if ($o.length) {
                e.preventDefault();
                var $this = $(this);
                if ($this.closest(".goto-list").length)
                {
                    $this.closest(".goto-list").find("li").removeClass("active");
                    $this.closest("li").addClass("active");
                    tabs_update_pointer($this.closest(".tabs"));
                }
                if ($this.closest(".goto-hash-change").length)
                {
                    location_hash_update($this.attr("href"));
                }
                goto_object($o);
            }
        }
    });
}

function goto_object($o, speed, doffset) {
    if (typeof speed === 'undefined') {
        speed = 700;
    }
    if ($o.length)
    {
        var offset = $o.offset().top;
        if (typeof doffset !== 'undefined') {
            offset += doffset;
        }
        var $offset = $(".js-goto-offset").first();
        if ($offset.length) {
            offset -= $offset.outerHeight();
        }
        if ($o.data("goto-offset-element")) {
            offset -= $($o.data("goto-offset-element")).outerHeight();
        }
        goto_offset(offset, speed, $o);
    }
}

function goto_offset(offset, speed, $o)
{
    if (typeof speed === 'undefined') {
        speed = 700;
    }
    $("html, body").stop(true, true).animate({scrollTop: offset}, speed, function(){
        $(this).trigger('goto-end');
        if ($o) {
            $o.trigger('goto-end');
        }
    });
}



function aos_init() {
    AOS.init({
        offset: (window.matchMedia("(max-width: 767px)").matches)?50:100,
        duration: 600,
        delay: 0, // 100
        once: true,
        //startEvent: 'load',
    });
}



function misc_init() {

    $(document).on("after-action-success", ".form--quote", function (e) {
        var url = $(this).data("redirect-url");
        var timeout = $(this).data("redirect-after");
        if (typeof timeout == 'undefined') {
            timeout = 0;
        }
        if (url) {
            setTimeout(function(){
                location.href = url;
            }, timeout);
        }
    });

    $(document).on("submit", ".js-form-hero", function (e) {
        loader_add($(this).find('[type="submit"]'));
    });

    if (urlParams && urlParams.price) {
        $('input[name="price"]').val(urlParams.price).trigger('change');
    }
}