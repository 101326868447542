



/*

Custom Checkboxes

 */

function checkbox_plain_init($o, context) {
    if (!$o) {
        $o = context?$('.checkbox-plain-js', context):$('.checkbox-plain-js');
    }
    $o = $o.not(".checkbox-plain-js-inited");
    $o.find("[type='checkbox'], [type='radio']").on("change change-pseudo", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        var name = $(this).attr("name");
        if ($(this).prop("type") == 'radio') {
            var $radios = $(this).closest("form").find("[name='"+name+"']");
            if (!$radios.length) $radios = $("input[name='"+name+"']");
            $radios = $radios.not(':checked');
            $radios.closest(".checkbox-plain-js").removeClass("checked");
        }
        if ($(this).prop("checked")) $o.addClass("checked");
        else $o.removeClass("checked");
        if ($(this).prop("disabled")) $o.addClass("disabled");
        else $o.removeClass("disabled");
    }).on("focus", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        if (!$(this).prop("disabled")) $o.addClass("focused");
    }).on("blur", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        $o.removeClass("focused");
    }).on('click', function(e){
        var $input = $(this).data("uncheckable");
        if (typeof $(this).data("uncheckable") !== 'undefined') {
            var previousValue = $(this).attr('data-previousValue');
            var name = $(this).attr('name');
            var $radios = $(this).closest("form").find("[name='"+name+"']");
            if (!$radios.length) $radios = $("input[name='"+name+"']");
            if (previousValue == 'checked') {
                $(this).prop('checked', false).trigger('change');
                $(this).attr('data-previousValue', false);
            } else {
                $radios.attr('data-previousValue', false);
                $(this).attr('data-previousValue', 'checked');
            }
        }
    }).each(function(){
        var $o = $(this).closest(".checkbox-plain-js");
        if ($(this).prop("checked")) $o.addClass("checked");
        if ($(this).prop("disabled")) $o.addClass("disabled");
    });
    $o.addClass("checkbox-plain-js-inited");
}



/*
* Range Slider
* */

function nouislider_init() {

    if (!$("body").hasClass("nouislider-inited")) {

        $(document).on("change keyup", ".js-range-input input", function (e) { //  keyup
            if (e.type == 'keyup' && $(this).val() === '') {
                return true;
            }
            $range = $(this).closest(".js-range");
            $slider = $range.find(".js-range-slider");
            $inputs = $range.find(".js-range-input input");
            if ($slider.length && $inputs.length > 0) {
                if (e.type == 'keyup' && ($(this).val() < $slider[0].noUiSlider.options.range.min[0] || $(this).val() > $slider[0].noUiSlider.options.range.max[0])) {
                    return true;
                }
                var values = [];
                $inputs.each(function (i) {
                    if (!i && $(this).val() * 1 > $inputs.eq(1).val() * 1) { // если вбито значение во первый инпут больше или равное значению во втором инпуте
                        $(this).val(-9999999999);
                    }
                    if (i && !($(this).val() * 1)) { // если не вбито значение во второй инпут
                        $(this).val(9999999999);
                    }
                    values.push($(this).val());
                });
                $slider[0].noUiSlider.set(values);
            }
        });

        $("body").addClass("nouislider-inited")
    }


    $(".js-range-slider").not('.nouislider-inited').each(function () {

        var rangeMin = $(this).data("min") || 0,
            connect = $(this).data("connect") || 'lower',
            rangeMax = $(this).data("max") || 0,
            rangeStep = $(this).data("step") || 1,
            rangeMargin = $(this).data("margin") || 0,
            rangeDecimals = $(this).data("decimals") || 0,
            rangeReduce = $(this).data("reduce") || 0,
            rangePrefix = $(this).data("prefix") || '',
            rangeSuffix = $(this).data("suffix") || '',
            rangeSnap = $(this).data("snap") || false,
            rangeRange = $(this).data("range") || false,
            rangePips = $(this).data("pips") || false,
            rangeFormatFunction = $(this).data("range-format-function") || false;

        var $range = $(this).closest(".js-range");
        var range_slider_element = this;

        var rangeMinData = parseFloat($(this).attr('data-minValue')),
            rangeMaxData = parseFloat($(this).attr('data-maxValue'));

        if (rangeMinData >= rangeMax) {
            rangeMinData = rangeMin;
        }
        if (rangeMaxData <= rangeMin) {
            rangeMaxData = rangeMax;
        }
        if (rangeMin == rangeMax) {
            rangeMin--;
            rangeMax++;
        }
        if (typeof $(this).attr('data-value') !== 'undefined') {
            var rangeData = parseFloat($(this).attr('data-value'));
            if (rangeData >= rangeMax) {
                rangeData = rangeMin;
            }
            if (rangeData <= rangeMin) {
                rangeData = rangeMax;
            }
        }

        if (!rangeRange) {
            rangeRange = {
                'min': Number((rangeMin*1).toFixed(rangeDecimals)),
                'max': Number((rangeMax*1).toFixed(rangeDecimals))
            };
        }

        def_format = wNumb({
            decimals: rangeDecimals,
            thousand: ' ',
            prefix: rangePrefix,
            suffix: rangeSuffix,
            encoder: function(value) {
                return (value/(Math.pow(10, rangeReduce)));
            }
        });

        noUiSlider.create(range_slider_element, {
            start: (typeof rangeData !== 'undefined')?rangeData:[rangeMinData, rangeMaxData],
            connect: connect,
            direction: 'ltr',
            orientation: 'horizontal',
            behaviour: 'tap-drag',
            margin: rangeMargin,
            step: rangeStep,
            snap: rangeSnap,
            range: rangeRange,
            pips: rangePips,
            tooltips: (typeof rangeData !== 'undefined')?def_format:[def_format, def_format],
            format: {
                to: function (value) {
                    return Number((value*1).toFixed(rangeDecimals));
                },
                from: function (value) {
                    return Number((value*1).toFixed(rangeDecimals));
                }
            }
        });

        $range.find(".js-range-input-get").val(rangeData).data('range-value-changed', rangeData).data('input-format', def_format).data('input-format-function', rangeFormatFunction);
        $range.find(".js-range-input-get-min").val(rangeMinData).data('range-value-changed', rangeMinData).data('input-format', def_format).data('input-format-function', rangeFormatFunction);
        $range.find(".js-range-input-get-max").val(rangeMaxData).data('range-value-changed', rangeMaxData).data('input-format', def_format).data('input-format-function', rangeFormatFunction);

        range_slider_element.noUiSlider.on('update', function (values, handle) {
            var $range = $(range_slider_element).closest(".js-range");
            var $inputs = $range.find(".js-range-input");
            var $input = $range.find(".js-range-input-get");
            var $input_min = $range.find(".js-range-input-get-min");
            var $input_max = $range.find(".js-range-input-get-max");
            $input.val(values[0]).trigger("range-updated").closest(".js-range-input").toggleClass("active", values[0] != this.options.range.min);
            $input_min.val(values[0]).trigger("range-updated").closest(".js-range-input").toggleClass("active", values[0] != this.options.range.min);
            $input_max.val(values[1]).trigger("range-updated").closest(".js-range-input").toggleClass("active", values[1] != this.options.range.max);
            if (rangeFormatFunction && typeof window[rangeFormatFunction] === 'function') {
                $range.find(".js-range-set").html(window[rangeFormatFunction](values[0]));
                $range.find(".js-range-min-set").html(window[rangeFormatFunction](values[0]));
                $range.find(".js-range-max-set").html(window[rangeFormatFunction](values[1]));
            }
            else {
                $range.find(".js-range-set").html(def_format.to(values[0]));
                $range.find(".js-range-min-set").html(def_format.to(values[0]));
                $range.find(".js-range-max-set").html(def_format.to(values[1]));
            }
        });

        range_slider_element.noUiSlider.on('change', function (values, handle) {
            var $range = $(range_slider_element).closest(".js-range");
            var $input = $range.find(".js-range-input-get");
            var $input_min = $range.find(".js-range-input-get-min");
            var $input_max = $range.find(".js-range-input-get-max");
            if ($input.data('range-value-changed') != values[0]) {
                $input.trigger("range-changed");
            }
            if ($input_min.data('range-value-changed') != values[0]) {
                $input_min.trigger("range-changed");
            }
            if ($input_max.data('range-value-changed') != values[1]) {
                $input_max.trigger("range-changed");
            }
            $input.data('range-value-changed', values[0]);
            $input_min.data('range-value-changed', values[0]);
            $input_max.data('range-value-changed', values[1]);
        });

        if (rangeData != 0) {
            range_slider_element.noUiSlider.set([rangeData, null]);
        }
        if (rangeMinData != 0) {
            range_slider_element.noUiSlider.set([rangeMinData, null]);
        }
        if (rangeMaxData != 0) {
            range_slider_element.noUiSlider.set([null, rangeMaxData]);
        }

        $(this).addClass('nouislider-inited');
    });
}